import "./App.css";
import { AlertColor, CssBaseline, ThemeProvider } from "@mui/material";
import Login from "./pages/Login";
import { BaseTheme } from "./theme/BaseTheme";
import { useLocation } from "react-router-dom";
import { useState, useEffect, lazy, Suspense, createContext } from "react";
import ListsSkeleton from "./components/skeletons/ListsSkeleton";
import Notification from "./components/ui/Notification";
import QueryProvider from "./lib/query-provider";
import { socket } from "./socket";
import { setInSessionStorage } from "./utils/HelperFunctions";

const Sidebar = lazy(() => import("./components/Sidebar"));
const AppRoutes = lazy(() => import("./Routes/Routes"));

export const SnackbarContext = createContext<any>({});

document.addEventListener("wheel", function (event) {
  if ((document.activeElement as any)?.type === "number") {
    (document.activeElement as any)?.blur();
  }
});

export type SnackbarPropType = {
  message: string;
  severity: AlertColor | undefined;
  open: boolean;
};

function App() {
  const taskCountFromSession = sessionStorage.getItem("task_count");
  const location = useLocation();
  //const [isSocketConnected, setIsSocketConnected] = useState(socket.connected);
  const [taskEvents, setTaskEvents] = useState<{ task_count: number }>({
    task_count: taskCountFromSession ? parseInt(taskCountFromSession) : 0,
  });
  const [snackbar, setSnackbar] = useState<SnackbarPropType>({
    message: "",
    severity: "info",
    open: false,
  });
  // useEffect(() => {
  //   function onConnect() {
  //     setIsSocketConnected(true);
  //   }

  //   function onDisconnect() {
  //     setIsSocketConnected(false);
  //   }

  //   function onTaskEvent(value: any) {
  //     setTaskEvents(value);
  //     setInSessionStorage("task_count", value.task_count);
  //   }

  // socket.on("connect", onConnect);
  // socket.on("disconnect", onDisconnect);
  // socket.on("task_count", onTaskEvent);

  // return () => {
  //   socket.off("connect", onConnect);
  //   socket.off("disconnect", onDisconnect);
  //   socket.off("task_count", onTaskEvent);
  // };
  // }, []);

  if (location.pathname === "/" || location.pathname === "/login") {
    return (
      <QueryProvider>
        <SnackbarContext.Provider value={{ snackbar, setSnackbar }}>
          <ThemeProvider theme={BaseTheme}>
            <Login taskEvent={taskEvents} />
            <Notification
              open={snackbar.open}
              severity={snackbar.severity}
              message={snackbar.message}
            />
          </ThemeProvider>
        </SnackbarContext.Provider>
      </QueryProvider>
    );
  }
  return (
    <QueryProvider>
      <SnackbarContext.Provider value={{ snackbar, setSnackbar }}>
        <ThemeProvider theme={BaseTheme}>
          <CssBaseline />
          <Suspense fallback={<ListsSkeleton />}>
            <Sidebar socketEvent={taskEvents}>
              <AppRoutes />
            </Sidebar>
          </Suspense>
          <Notification
            open={snackbar.open}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        </ThemeProvider>
      </SnackbarContext.Provider>
    </QueryProvider>
  );
}

export default App;
