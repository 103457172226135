import { createTheme } from "@mui/material";

export const BaseTheme = createTheme({
  typography: {
    fontFamily: "Inter Tight, sans-serif",
    fontWeightRegular: 400,
    fontWeightLight: 300,
    fontWeightMedium: 600,
    fontWeightBold: 800,
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    // primary: {
    //   main: "#36b8ff",
    // },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          textTransform: "none",
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            textTransform: "none",
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            textTransform: "none",
          },
        },
      ],
    },
  },
});
